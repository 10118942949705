import { Chart } from 'primereact/chart';
import React, { useContext, useEffect, useState } from 'react';
import { LayoutContext } from './../layout/context/layoutcontext';
import ViewAsistenciaReportAPI from './../model/api/apis/ViewAsistenciaReport';
import { obtenerNombreMes, roundNum } from './../utils';
import { connect } from 'react-redux';
import { UsuarioActions, UsuarioSelectors } from '../model/redux/reducers/UsuarioReducer';

const Dashboard = (props) => {
    const [estudiantesActivos, setEstudiantesActivos] = useState(0);
    const [newEstudiantesActivos, setNewEstudiantesActivos] = useState(0);

    const [porcentageAsistencia, setPorcentageAsistencia] = useState(0);
    const [mesEvaluarAsistencia, setMesEvaluarAsistencia] = useState(undefined);

    const [cantidadFacturaVencida, setCantidadFacturaVencida] = useState(0);
    const [cantidadFacturaVencidaDosSemana, setCantidadFacturaVencidaDosSemana] = useState(0);
    const [cantidadFacturaProximaVencer, setCantidadFacturaProximaVencer] = useState(0);
    const [cantidadFacturaProximaVencerSaldada, setCantidadFacturaProximaVencerSaldada] = useState(0);

    const [estudiantesPorEdadData, setEstudiantesPorEdadData] = useState([]);

    const [facturasVencidaPorMesData, setFacturasVencidaPorMesData] = useState([]);
    const [facturasSaldadasPorMesData, setFacturasSaldadasPorMesData] = useState([]);

    const [lineOptions, setLineOptions] = useState(null);
    const { layoutConfig } = useContext(LayoutContext);

    useEffect(() => {
        obtenerDataReport();
    }, []);

    const lineData = {
        labels: facturasVencidaPorMesData.map((factura) => obtenerNombreMes(factura.mes)),//['January', 'February', 'March', 'April', 'May', 'June', 'July'],
        datasets: [
            {
                label: 'Atrasada',
                data: facturasVencidaPorMesData.map((factura) => factura.cantidad),////[65, 59, 80, 81, 56, 55, 40],
                fill: false,
                backgroundColor: '#2f4860',
                borderColor: '#2f4860',
                tension: 0.4
            },
            {
                label: 'Saldada',
                data: facturasSaldadasPorMesData.map((factura) => factura.cantidad),
                fill: false,
                backgroundColor: '#00bb7e',
                borderColor: '#00bb7e',
                tension: 0.4
            }
        ]
    };

    const obtenerDataReport = async () => {
        try {
            let result = await ViewAsistenciaReportAPI.getDataDashBoardReport({ "codigoSucursal": props.sucursalSeleccionada?.codigoSucursal });
            setEstudiantesActivos(result?.cantidadEstudianteActivo)
            setNewEstudiantesActivos(result?.cantidadEstudianteActivoNew)
            setPorcentageAsistencia(result?.viewAsistenciaReport?.porcentajeAsistencia > 100 ? 99 : result?.viewAsistenciaReport?.porcentajeAsistencia)
            setMesEvaluarAsistencia(result?.viewAsistenciaReport?.mesEvaluado)
            setCantidadFacturaVencida(result?.cantidadFacturaVencida)
            setCantidadFacturaVencidaDosSemana(result?.cantidadFacturaVencidaDosSemana)
            setCantidadFacturaProximaVencer(result?.cantidadFacturaProximaVencer)
            setCantidadFacturaProximaVencerSaldada(result?.cantidadFacturaProximaVencerSaldada)
            setEstudiantesPorEdadData(result?.estudiantesPorEdad || [])
            setFacturasVencidaPorMesData(result?.facturasVencidaPorMes || [])
            setFacturasSaldadasPorMesData(result?.facturasSaldadasPorMes || [])
        } catch (error) {
       
        }

    }

    const applyLightTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };

        setLineOptions(lineOptions);
    };

    const applyDarkTheme = () => {
        const lineOptions = {
            plugins: {
                legend: {
                    labels: {
                        color: '#ebedef'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)'
                    }
                },
                y: {
                    ticks: {
                        color: '#ebedef'
                    },
                    grid: {
                        color: 'rgba(160, 167, 181, .3)'
                    }
                }
            }
        };

        setLineOptions(lineOptions);
    };

    useEffect(() => {
        if (layoutConfig.colorScheme === 'light') {
            applyLightTheme();
        } else {
            applyDarkTheme();
        }
    }, [layoutConfig.colorScheme]);


    function ItemEstudiantePorEdad({ items }) {
        const colores = [
            "orange-500",
            "cyan-500",
            "pink-500",
            "green-500",
            "purple-500",
            "teal-500",
            "purple-600",
            "green-600",
            "orange-600",
            "cyan-500",
            "cyan-500",
            "cyan-500",
            "cyan-500",
        ];
        return (
            <ul className="list-none p-0 m-0">
                {items.map((item, index) => (

                    <li key={item?.ageRange} className="flex flex-column md:flex-row  md:justify-content-between mb-4">
                        <div>
                            <span className="text-900 font-medium mr-2 mb-1 md:mb-0">{item?.ageRange}</span>
                            <div className="mt-1 text-600">{item?.count}</div>
                        </div>
                        <div className="mt-2 md:mt-0 flex align-items-center">
                            <div className="surface-300 border-round overflow-hidden w-10rem lg:w-6rem" style={{ height: '8px' }}>
                                <div
                                    className={`bg-${colores[index % colores.length]} h-full`}
                                    style={{ width: item?.porcentage + '%' }} />
                            </div>
                            <span className={`text-${colores[index % colores.length]} ml-3 font-medium`}>{ roundNum(item?.porcentage) +'%'}</span>
                        </div>
                    </li>
                ))}
            </ul>
        );
    }


    return (
        <div className="grid">
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Estudiantes Activos</span>
                            <div className="text-900 font-medium text-xl">{estudiantesActivos || 0}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-user text-blue-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{(newEstudiantesActivos || 0) + ' Nuevo'} </span>
                    <span className="text-500">Desde hace 2 semanas</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Asistencia</span>
                            <div className="text-900 font-medium text-xl">{Math.round((porcentageAsistencia || 0)) + '%'}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-book text-orange-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium"> {mesEvaluarAsistencia != undefined ? obtenerNombreMes(mesEvaluarAsistencia) : 'N/A'}</span>

                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">Facturas Atrasada</span>
                            <div className="text-900 font-medium text-xl">{cantidadFacturaVencida || 0}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-money-bill text-cyan-500 text-xl" />
                        </div>
                    </div>
                    <span className="text-green-500 font-medium">{cantidadFacturaVencidaDosSemana || 0} </span>
                    <span className="text-500">Desde hace 2 semanas</span>
                </div>
            </div>
            <div className="col-12 lg:col-6 xl:col-3">
                <div className="card mb-0">
                    <div className="flex justify-content-between mb-3">
                        <div>
                            <span className="block text-500 font-medium mb-3">{'Factura Prox. Venc.'}</span>
                            <div className="text-900 font-medium text-xl">{cantidadFacturaProximaVencer || 0}</div>
                        </div>
                        <div className="flex align-items-center justify-content-center bg-purple-100 border-round" style={{ width: '2.5rem', height: '2.5rem' }}>
                            <i className="pi pi-wallet text-purple-500 text-xl" />
                        </div>
                    </div>
                    <span className={`text-${cantidadFacturaProximaVencer / 2 > cantidadFacturaProximaVencerSaldada ? 'red-500' : 'green-500'} font-medium`}>{cantidadFacturaProximaVencerSaldada} </span>
                    <span className="text-500">Pagada</span>
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <div className="flex justify-content-between align-items-center mb-5">
                        <h5>Estudiante por Edad</h5>
                        <div>

                        </div>
                    </div>

                    <ItemEstudiantePorEdad items={estudiantesPorEdadData} />
                </div>
            </div>

            <div className="col-12 xl:col-6">
                <div className="card">
                    <h5>Factura Pagada Vs Atraso (Último 6 Meses)</h5>
                    <Chart type="line" data={lineData} options={lineOptions} />
                </div>
            </div>
        </div>
    );
};

function mapSateToProps(state) {

    const sucursalSeleccionada = UsuarioSelectors.sucursalSeleccionada(state)

    return { sucursalSeleccionada };
}

function mapDispatchToProps(dispatch) {
    return {  };
}

export default connect(mapSateToProps, mapDispatchToProps)(Dashboard);
