import {client} from '../client';

const PATH = 'viewasistenciareport';

const ENDPOINTS = {
  GET_DASHBOARD_REPORT: `${PATH}/dashboardreport`
};

const ViewAsistenciaReportAPI = {
  
  async getDataDashBoardReport(params={}){
    return client.requestWithParams(ENDPOINTS.GET_DASHBOARD_REPORT, params);
  },

};

export default ViewAsistenciaReportAPI;
